import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { AppConfigurationService } from 'app/common/configuration';
import { Compartment, CreateCompartmentDto, UpdateCompartmentDto } from 'app/core/models';
import { ApiEndPoints } from 'app/shared/constants/api-endpoints';

import { ErrorHandlerService } from '../error-handling';

@Injectable({ providedIn: 'root' })
export class CompartmentApiService {
    protected readonly http = inject(HttpClient);
    protected readonly appConfigurationService = inject(AppConfigurationService);
    protected readonly translate = inject(TranslateService);
    protected readonly errorHandlerService = inject(ErrorHandlerService);
    private readonly baseUrl = this.appConfigurationService.getBaseUrl('spatial');

    public getCompartmentList(siteId: string, levelId: string, spaceId: string): Observable<Compartment[]> {
        return this.http.get<Compartment[]>(
            ApiEndPoints.compartments.getList(this.baseUrl, siteId, levelId, spaceId),
        );
    }

    private getCompartmentById(siteId: string, id: string): Observable<Compartment> {
        return this.http.get<Compartment>(
            ApiEndPoints.compartments.getUpdateOrDeleteById(this.baseUrl, siteId, id),
        );
    }

    public createCompartments(
        siteId: string,
        createCompartmentDto: CreateCompartmentDto[],
    ): Observable<Compartment[]> {
        return this.http.post<Compartment[]>(
            ApiEndPoints.compartments.create(this.baseUrl, siteId) + '/batch',
            { spaces: createCompartmentDto },
        );
    }

    public updateCompartment(
        siteId: string,
        id: string,
        updateCompartmentDto: UpdateCompartmentDto,
    ): Observable<Compartment> {
        if (!id) {
            this.errorHandlerService.handleScriptMessages(
                this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'),
            );
            return throwError(() => new Error(this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE')));
        }

        return this.getCompartmentById(siteId, id).pipe(
            switchMap((existingCompartment) => {
                if (existingCompartment.id !== id) {
                    this.errorHandlerService.handleScriptMessages(
                        this.translate.instant('ERROR.COMPARTMENT_ID_MISMATCH'),
                    );
                    return throwError(
                        () => new Error(this.translate.instant('ERROR.COMPARTMENT_ID_MISMATCH')),
                    );
                }

                const { name: _, ...otherProperties } = existingCompartment;

                const updatePayload: Compartment = {
                    ...updateCompartmentDto,
                    ...otherProperties,
                };

                return this.http.put<Compartment>(
                    ApiEndPoints.compartments.getUpdateOrDeleteById(this.baseUrl, siteId, id),
                    updatePayload,
                );
            }),
            catchError(() => {
                this.errorHandlerService.handleScriptMessages(
                    this.translate.instant('ERROR.UPDATING_COMPARTMENT'),
                );
                return throwError(() => new Error(this.translate.instant('ERROR.UPDATING_COMPARTMENT')));
            }),
        );
    }

    public deleteCompartment(siteId: string, id: string): Observable<void> {
        return this.http.delete<void>(
            ApiEndPoints.compartments.getUpdateOrDeleteById(this.baseUrl, siteId, id),
        );
    }
}
